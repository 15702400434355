/* Import Third Party Styles for each IQ-Theme */
@import 'third-party-theme-default';
@import 'third-party-theme-high-contrast';

/* Import Third-Party Font-Styles  */
@import 'third-party-fonts';

/* Import IQ-Library Styles */
@import 'iq-libraries';

/* Import Portal-Styles */
@import 'portal';
