@import 'iq-colors';
@import 'iq-variables';
@import 'themes/theme-default';

// Theme Colors
$primary-schmuckfarbe: map-get($theme-default, 'primary-schmuckfarbe');
$error: map-get($theme-default, 'error');

$ampel-gruen: map-get($theme-default, 'ampel-gruen');
$ampel-gelb: map-get($theme-default, 'ampel-gelb');
$ampel-rot: map-get($theme-default, 'ampel-rot');

// Various colors
$background-body: map-get($theme-default, 'background-body');
$background-tooltip: map-get($theme-default, 'background-tooltip');

$text: map-get($theme-default, 'text');
$text-muted-color: map-get($theme-default, 'text-muted');

$border: map-get($theme-default, 'border');
$border-input: map-get($theme-default, 'border-input');

$background-dropdown-menu-item-hover: map-get($theme-default, 'background-dropdown-menu-item-hover');

//Navigation
$sidebar-width: 245px;
$sidebar-width-mini: 70px;
$nav-profil-pattern: none; // url("../assets/images/patterns/header-profile.png");

// Tabs
$background-tab-active: map-get($theme-default, 'background-tab-active');

// Tables
$background-table-hover: map-get($theme-default, 'background-table-hover');
$background-table-striped-odd: map-get($theme-default, 'background-table-striped-odd');

.theme-default {
  @import 'third-party';
}
